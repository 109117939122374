<template>
  <div class="no-data">
    <img class="images" src="@/assets/images/free.png" alt="" srcset="">
    <div>{{ text }}</div>
  </div>
</template>
<script>
export default {
  props: {
    text: {
      type: String,
      default: '暂无数据',
    },
  },
}
</script>
<style lang="less" scoped>
.no-data{ padding:100px 0; margin:0 auto; text-align: center;
  .images{ width: 120px; height: 120px; margin-bottom: 20px;}
  div{ color: #787878;}
}
</style>
